.Question-Wrapper {
  display: flex; 
  justify-content: center;
  /* width: 100%; */
  /* border: 1px solid red; */
  /* width: 100vh; */
  /* max-width: 900px; */
  /* height: auto; */
  background: #fafafa;
  margin-top: 1.3rem;
  border-radius: 10px;
  /* border: 1px solid red;  */
}
@media (min-width: 300px) {
  .Question-Wrapper {
    max-width: 1000px;
    width: 90vw;
    margin-left: 10px;
    /* background-color: pink; */
  }
}
@media (max-width: 800) {
  .Question-Wrapper {
    width: 10%;
    
  }
}
/* @media(max-width:540px) {
        .Question-Wrapper{
          width:100%;
        }  
  } */

.Question-Container {
  display: flex;
  width: 95%;
  margin: auto;
}
.Question-Top {
  display: flex;
  gap: 0px 13px;
  /* border: 1px solid red; */
}
.Question-Top-Para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #6b6b6b;
}
@media (max-width: 540px) {
  .Question-Top-Para {
    font-size: 14px;
  }
}
.Question {
  display: flex;
  width: 95%;
  margin: auto;
  margin-top: 7px;
  /* border: 1px solid red; */
}
.Question-Para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #6b6b6b;
  text-align: justify;
}
@media (max-width: 540px) {
  .Question-Para {
    font-size: 14px;
  }
}
/* .Container {
  display: flex;
  flex-direction: column;
} */
.Option{
  width: 100%;
border: .5px solid #ecd5d5;
/* padding-top: 10px;
padding:10px 10px ;
  padding-bottom: 0px; */
  padding: 10px;
  
}
@media (max-width: 540px) {
  .Option {
  padding: 0px;

  }
}
.Options{
  width: 100%;
  display: flex;
  
}
@media (max-width: 540px) {
  .Options {
  flex-direction: column;
    /* Stack options vertically on smaller screens */
    gap: 0px;
   
  }
}
.OptionA {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  padding: 10px;
  border-radius: 5px;


  margin: auto;
  align-items: center;
  background-color: #c5d9cf;
  justify-content: center;
  margin-top: 1px;
  /* border: 1px solid red; */
}
@media (max-width: 540px) {
  .OptionA {
   padding: 3px;

  }
}
.CircleA {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 0.666667px solid rgba(107, 107, 107, 0.51);
}

.CircleA-Para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12.3284px;
  line-height: 27px;
  color: rgba(107, 107, 107, 0.51);
  text-align: center;
}
.Middle {
  width: 86%;
  /* border: 1px solid blueviolet; */
}
@media (max-width: 540px) {
  .Middle {
    width: 68%;
   font-size: medium;
  }
}
.Middle-Para {
  font-family: "Rubik";
  font-style: "normal";
  font-weight: 400;
  font-size: 16px;
  color: #6b6b6b;
}
@media (max-width: 540px) {
  .Middle-Para {
    font-size: 14.5px;
  }
}
.BookMark{
  display: flex;
  justify-content: end;
justify-items: end;


}