input{
  width: 285px;
  height: 39px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #6b6b6b;
  outline: none;
  border: none;
  padding-left: 18px;
  box-sizing: border-box;
  border-radius: 7.68748px;
  background: #f5f7fb;
}
.main {
    display:flex;
    /* border: 10px solid red; */
    justify-content: center;
    height:40px;
    background-color: #f5f7fb;
    margin-top: 1.7rem  ;
    border-radius: 7.68748px;
    border: 1px solid #f5f7fb;
    position: relative;
}
.Btn{
  /* border: 1px solid red; */
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 28px;
 
}
.Border{
  border: 1px solid #ecf0f1;
}
.Line {
  margin-top: 1.7rem;
}
form{
  background-color: white;
   padding: 20px 20px; 
    
}
.IMG {
  width: 19px;
   height: 16px; 
}

.Cancel-Btn {
  width:95px;
  height:35px;
  background: #F5F7FB;
  border: none;
  border-radius: 10.5672px;
  outline: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: rgba(21, 25, 32, 0.5);
  cursor: pointer;
}
.Send-Btn {
  width:95px;
  height:35px;
  background: #1472BA;
  border: none;
  border-radius: 10.5672px;
  outline: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
 position: relative;
}
.Send-Btn:active {
  background: #314859;
}
/* 
  .send-button-text {
    font: bold 15px 'Quicksand', san-serif;
    color: #FFFFFF;
    transition: all 0.2s;
  } */

.form-Input span{
  font-size: 12px;
  display: none;
   padding: 6px; 
   color: red;
}

input:invalid[focused="true"]{
  border: 1px solid red;
}
/* input:focus{
  border: 1px solid red;
} */
input:invalid[focused="true"] ~ span{
  display: block;
}
