.TopBar{
        position: sticky;
        top: 0;
        background: #1472BA;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;  
}

.RightGridSticky {
        position: sticky;
        top: 0;
        z-index: 2;
        /* Ensure it stays above other elements */
        background-color: #fff;
        /* Optional: Add a background to maintain visibility */
}

/* For small screens specifically */
@media (max-width: 540px) {
        .RightGridSticky {
                top: 0;
        }
}
.scroll {      
display: flex;
                justify-content: center;
              
            
                scroll-behavior: smooth; 
                /* border: .5px solid #f7f1f1; */
                overflow: auto;
              height: 450px;
           
           
              background-color: antiquewhite;
              width: 100%;
       
}
@media(max-width:540px) {
        .scroll{
                height: 40px;
                padding-top: 5px;
                padding-bottom: 3px;
        }
}
.position{
                       
  background: white; 
                     
 padding-Bottom: 10px,
       
}
@media (max-width:540px){
    .position{
        padding-Bottom: 5px,
    }
}
.Left-TopBar-Para { 
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size:  12px;
        color: #F1F7F6;
        padding-bottom: 5px; 
        position: absolute;
        left:  5%;
        top: 68%;   
}
@media(max-width:540px) {
        .Left-TopBar-Para {   
         padding-bottom: 2px;  
         font-size:  10.5px;
        } 
}

.Right-TopBar-Para {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 18.4927px;
        line-height: 32px;
        color: #F1F7F6;
}
.menu-icon {
        display: none;  
      }
@media (max-width: 540px) {
        .menu-icon {
          display: block;
          position: absolute;
          right:  12%;
          display:flex;   
          
        }
}
.Left-Container{
    display: flex ;
    /* background-color: #6B6B6B; */
 
}

@media (max-width: 540px) {
        .Left-Container {
                flex-direction: column;
                
        }
}

.Left-Container-width{
        width: 100%;  
        /* position: relative; */
}
@media(max-width:540px) {
        .Left-Container-width{
          width:100%;
        }  
  }
.Left-Container-Main{
        position: relative;
        /* top: 56px; */
        z-index: 1;
        display: flex;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #b71313;
        background: #ecf2f2;
        width: 100%;
        margin-left:auto;
        margin-right: auto;
           
}
@media(max-width:540px) {
        .Left-Container-Main{
                margin-top: 0px;
         width: 100%;
         
          font-size: 13px;
        }  
  }

.Left-Container-Header{
        width: 50%;
        /* border: 1px solid red; */
}
@media(max-width:540px) {
        .Left-Container-Header{
          width:50%;    
        }  
  }
.Left-Container-Mark{
 display: flex; 
 gap: 54px 54px;
 padding-left: 40px;
 /* border: 1px solid red; */
 line-height: 20px;
}
@media(max-width:540px) {
        .Left-Container-Mark{
               
                padding-left: 20px; 
        }  
  }
.Left-Container-Question{

     display: flex; 
     gap: 0px 20px; 
     /* background-color: #6B6B6B; */
     padding-left: 40px;
     line-height: 20px;
  
}
@media(max-width:540px) {
        .Left-Container-Question{
               
                padding-left: 20px;
        }
}
.Left-Container-Timer{
        width: 50%;
        /* border: 1px solid red; */
}
.Left-Container-StartTime{
     display: flex; 
     gap: 0px 19px; 
     line-height: 20px;
}
.Left-Container-EndTime{
     display: flex; 
     gap: 0px 27px; 
     line-height: 20px;
}
@media(max-width:540px) {
        .Left-Container-EndTime{
                gap: 0px 20px;   
        }  
  }
.ExamPageCard{
        /* width: 86%; */
        
        margin-left: auto;
        margin-right: auto;
        
}
.RefWrapper{
position: sticky;
        top: 0;
        /* Adjust based on your layout */
        z-index: 10;
        /* Ensure it stays above other elements */
    top: 56px;
    width: 100%;
    background: rgb(255, 255, 255);
   
    /* overflow-x: hidden; */
    height: 92.5vh;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    /* display: none; */
}

@media (max-width: 768px) {
        .RefWrapper {
                top: 0;
                        /* You can customize this value */
                        z-index: 20;
                        /* Optional: Higher z-index for smaller screens */
        }
}
.RefMain{
  width: 90%;
  /* border: 1px solid red; */
}
@media (max-width: 540px) {
        .RefWrapper {
                position: sticky;
                        z-index: 0;
                    
                height: 130px;
        }
}
.RefPara{
        margin: 36px 0px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: #1472BA;        
}
@media (max-width:540px){
        .RefPara{
        margin:  0px;
        font-style: normal;
         font-weight: 600;
         font-size: 14px;
        }
}

.Answered{
 font-family: "Rubik";
 font-style: normal;
 font-weight: 400;
 font-size: 14.0316px;
 line-height: 24px;
 text-align: center;
 color: #6B6B6B;
}
@media (max-width:540px){
       .Answered {
        font-size: 12px;
        font-style: normal;
}
}
.Number{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  text-align: center;            
}
.Button-Wrapper {
 display: flex;
 justify-content: center;
 padding-bottom: 30px; 
margin-top: 25px;
 /* border: 1px solid red;      */
}
@media (max-width: 540px) {
        .Button-Wrapper {
                display: flex;
                margin-top: 6px;
        }
}
.Submit{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
/* margin-bottom: 40px; */
color: #F1F7F6;
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 15px;
text-align: center;
width: 145px;
/* border: 1px solid red; */
height: 50px;
background: #1472BA;
box-shadow: 5px 7px 30px rgba(1, 179, 154, 0.2);
border-radius: 5px;
border: none;
outline: none;
cursor: pointer;
} 
@media (max-width: 540px) {
        .Submit {
        height: 30px;
        width: 120px;
        }
}